import { createContext, useEffect, useState } from "react";
import AppLoading from "../components/common/loading/AppLoading";
import { NewUser, User } from "../types";
import { useMsal } from "@azure/msal-react";
import { Role } from "../types";
import ErrorMessage from "../components/common/error/ErrorMessage";
import ErrorMessageContactDisabled from "../components/common/error/ErrorMessageContactDisabled";
import { getContact, getUser } from "../data/Contact/queries";

type Props = {
  children: React.ReactNode;
  noInfoComponent: React.ReactNode;
};

interface UserContext {
  userData: User;
  updateUser: () => Promise<void>;
  userHasRole: (role?: Role) => boolean;
}

interface UpdateUserContext {
  updateUser: () => Promise<void>;
}
export const UserContext = createContext<UserContext | undefined>(undefined);

export const UpdateUserContext = createContext<UpdateUserContext | undefined>(
  undefined
);

export function UserProvider({ noInfoComponent, children }: Props) {
  const { instance } = useMsal();
  const [userData, setUserData] = useState<User | NewUser | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const updateUser = async (showLoading?: boolean) => {
    try {
      if (showLoading) setLoading(true);

      const msalAccount = instance.getActiveAccount();
      if (msalAccount) {
        const data = await getUser();
        if (!data) {
          setUserData({ newUser: true, msalInfo: msalAccount });
          return;
        }

        const userData: User = {
          newUser: false,
          msalInfo: msalAccount,
          emailaddress1: data.emailaddress1,
          fullname: `${data.firstname} ${data.lastname}`,
          firstname: data.firstname,
          lastname: data.lastname,
          contactid: data.contactid,
          telephone1: data.telephone1,
          _parentcustomerid_value: data._parentcustomerid_value,
          vdl_fonction: data.vdl_fonction,
          telephone2: data.telephone2,
          _vdl_roledesecurite_value: data._vdl_roledesecurite_value,
          _vdl_roledesecurite_value_Formatted:
            data._vdl_roledesecurite_value_Formatted,
          vdl_salutation: data.vdl_salutation,
          vdl_accesauportail: data.vdl_accesauportail,
          modifiedon: data.modifiedon,
          createdon: data.createdon,
          statecode: data.statecode,
        };
        setUserData(userData);
      }
    } catch (error) {
      setUserData(undefined);
    } finally {
      setLoading(false);
    }
  };

  function userHasRole(role?: Role): boolean {
    try {
      if (!userData || userData.newUser) return false;
      if (role === undefined) return true;
      if (!userData._vdl_roledesecurite_value) return false;

      if (role === "Usager") return true;
      if (role === "Employé") {
        return (
          userData._vdl_roledesecurite_value_Formatted === "Employé" ||
          userData._vdl_roledesecurite_value_Formatted === "Administrateur"
        );
      }

      return userData._vdl_roledesecurite_value_Formatted === "Administrateur";
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    updateUser();
  }, []);

  if (loading) {
    return <AppLoading />;
  }
  if (!userData)
    return (
      <ErrorMessage message="Erreur dans l'authentification. Erreur dans la récupération du contact" />
    );
  if (userData.newUser) {
    return (
      <UpdateUserContext.Provider
        value={{ updateUser: async () => updateUser(true) }}
      >
        {noInfoComponent}
      </UpdateUserContext.Provider>
    );
  }
  if ((!userData.newUser && !userData.contactid) || userData.contactid === "") {
    return (
      <ErrorMessage message="Erreur dans la récupération de l'utilisateur. N'est pas un nouvel utilisateur et aucun contact associé" />
    );
  }

  if (!userData.vdl_accesauportail || userData.statecode === 1) {
    return <ErrorMessageContactDisabled />;
  }

  return (
    <UserContext.Provider
      value={{
        userData: userData,
        updateUser: async () => updateUser(false),
        userHasRole: userHasRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
