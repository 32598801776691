import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box, Typography, useTheme } from "@mui/material";
import DataCard from "../components/common/cards/DataCard/DataCard";
import Edit from "@mui/icons-material/Edit";
import DataCardSkeleton from "../components/common/cards/DataCard/DataCardSkeleton";
import contactDisplayNames from "../data/Contact/displayNames";
import LinkIcon from "../components/common/buttons/icons/LinkIcon";
import SendIcon from "@mui/icons-material/Send";
import BlockIcon from "@mui/icons-material/Block";
import { LockOpen } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import {
  getDisplayFieldsFromRow,
  getStringFromField,
  getStringFromOptionSet,
} from "../data/common/fieldToString";
import { ContactPatch, ContactRow } from "../data/Contact/models";
import PrivateComponent from "../components/common/auth/PrivateComponent";
import ClickableIcon from "../components/common/buttons/icons/ClickableIcon";
import { useSnackbar } from "../hooks/useSnackbar";
import CustomChip from "../components/common/misc/CustomChip";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import { basePaths } from "../routes/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryError } from "../types";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import { getContact, updateContact } from "../data/Contact/queries";
import { contactOptionSetsChoices } from "../data/Contact/optionSetChoices";

const ContactPage = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const { id } = useParams();

  const { data, error, isLoading, isFetching } = useQuery<
    ContactRow,
    QueryError
  >({
    queryKey: ["Contact", id],
    queryFn: () => getContact(id),
  });

  const mutation = useMutation({
    mutationFn: ({
      contactid,
      contact,
    }: {
      contactid: string;
      contact: ContactPatch;
    }) => updateContact(contactid, contact),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Contact", id] });
    },
  });

  if (isLoading || isFetching || mutation.isPending)
    return <ContactPageLoading />;

  if (error || !data) return <QueryErrorMessage error={error} />;

  const handleSendInvite = async () => {
    mutation.mutate(
      {
        contactid: data.contactid,
        contact: {
          vdl_accesauportail: true,
          firstname: data.firstname,
          lastname: data.lastname,
          telephone1: data.telephone1,
          telephone2: data.telephone2 ?? undefined,
          vdl_fonction: data.vdl_fonction,
          vdl_salutation: data.vdl_salutation,
          emailaddress1: data.emailaddress1,
        },
      },
      {
        onSuccess: () => {
          showSnackbar("Contact invité", "success");
        },
        onError: () => {
          showSnackbar("Erreur dans lors de l'invitation", "error");
        },
      }
    );
  };

  return (
    <PageTitleLayout
      title={`${data.firstname} ${data.lastname}`}
      sideTitleComponent={
        <Box
          display="flex"
          rowGap="1rem"
          columnGap="1.5rem"
          alignItems="center"
          flexWrap="wrap"
        >
          <CustomChip
            color={data.vdl_accesauportail ? "green" : "grey"}
            text={
              data.vdl_accesauportail
                ? "Accès au portail"
                : "Pas accès au portail"
            }
          />
          <PrivateComponent
            roleMinimum={data.vdl_accesauportail ? "Administrateur" : "Employé"}
          >
            <Box display="flex" gap="1rem">
              <LinkIcon
                size="1.3rem"
                tooltip="Modifier ce contact"
                to={basePaths.edit}
                disabled={isFetching}
                icon={<Edit />}
                backgroundColor="background.default"
              />
              <PrivateComponent roleMinimum="Administrateur">
                {data.vdl_accesauportail ? null : (
                  <ClickableIcon
                    size="1.3rem"
                    tooltip="Envoyer une invitation"
                    icon={<SendIcon />}
                    handleClick={handleSendInvite}
                    disabled={isFetching}
                    backgroundColor="background.default"
                  />
                )}
              </PrivateComponent>
            </Box>
          </PrivateComponent>
        </Box>
      }
      underTitleComponent={
        <Typography fontWeight="bold" sx={{ color: '#fff' }}>
          {data._vdl_roledesecurite_value_Formatted}
        </Typography>
      }
    >
      <Box
        sx={{
          ...theme.custom.pageCardContainer,
        }}
      >
        <DataCard
          emptyTextPlaceHolder="---"
          title="Informations personnelles"
          displayFields={{
            vdl_salutation: {
              displayName: contactDisplayNames.vdl_salutation,
              value: getStringFromOptionSet(
                data.vdl_salutation,
                contactOptionSetsChoices.vdl_salutation
              ),
            },
            emailaddress1: {
              displayName: contactDisplayNames.emailaddress1,
              value: getStringFromField(data.emailaddress1),
            },
            firstname: {
              displayName: contactDisplayNames.firstname,
              value: getStringFromField(data.firstname),
            },
            lastname: {
              displayName: contactDisplayNames.lastname,
              value: getStringFromField(data.lastname),
            },
            telephone1: {
              displayName: contactDisplayNames.telephone1,
              value: getStringFromField(data.telephone1),
            },
            telephone2: {
              displayName: contactDisplayNames.telephone2,
              value: getStringFromField(data.telephone2),
            },
            vdl_fonction: {
              displayName: contactDisplayNames.vdl_fonction,
              value: getStringFromOptionSet(
                data.vdl_fonction,
                contactOptionSetsChoices.vdl_fonction
              ),
            }
          }}
        />
        <Box height="800px"></Box>
      </Box>
    </PageTitleLayout>
  );
};

export default ContactPage;

const ContactPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Profil">
      <BoxResponsivePadding>
        <DataCardSkeleton icon displayFieldsCount={8} />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
