import dayjs from "dayjs";
import { DisplayNames, OptionSetChoices, TableFields } from "../../types";
import { BaseRow, Lookup, OptionSetString } from "./types";

export function getStringFromField(
  field: Lookup | OptionSetString | string | number | Boolean | undefined | null
): string {
  if (!field) return "";
  if (typeof field === "boolean") {
    return field ? "Oui" : "Non";
  } else if (typeof field === "string") {
    return field;
  } else if (typeof field === "number") {
    return field.toString();
  } else if ("Name" in field) {
    return field.Name;
  }

  return "";
}

const monthNames = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export function getDisplayFieldsFromRow<T extends BaseRow>(
  obj: T,
  fields: (keyof T)[],
  displayNames: DisplayNames<T>
): TableFields {
  let _fields: TableFields = {};
  fields.forEach((fieldName) => {
    _fields[fieldName.toString()] = {
      displayName: displayNames[fieldName] ? displayNames[fieldName] : "",
      value: getStringFromField(obj[fieldName]),
    };
  });
  return _fields;
}

export function getStringFromOptionSet(
  value: number | null,
  choices: OptionSetChoices
): string {
  if (value === null) return "";

  const label = choices[value];

  if (label === undefined) return "";
  return label;
}

export function getStringFromMultipleOptionSet(
  value: number[] | null,
  choices: OptionSetChoices
): string {
  if (value === null || value.length === 0) return "";

  const _temp: string[] = [];
  value.forEach((v) => {
    const label = choices[v];
    if (label) _temp.push(label);
  });

  return _temp.join(", ");
}

export function dateStringToShortStringUTC(dateString?: string | null): string {
  if (!dateString || dateString === "") return "";
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  if (isNaN(year) || isNaN(year) || isNaN(year)) {
    return "";
  }
  return `${year}-${month}-${day} `;
}

export function dateStringToYearStringUTC(dateString?: string | null): string {
  if (!dateString || dateString === "") return "";
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  return isNaN(year) ? "" : year.toString();
}

export function dateTimeStringToStringUTC(dateString?: string | null): string {
  if (!dateString || dateString === "") return "";
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  if (isNaN(year)) {
    return "";
  }
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function timeStringToStringUTC(dateString?: string | null): string {
  if (!dateString || dateString === "") return "";
  const date = new Date(dateString);
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function dateStringToLongWithDayStringUTC(
  dateString?: string | null
): string {
  if (!dateString || dateString === "") return "";
  const date = new Date(dateString);
  const year = date.getUTCFullYear();

  const dayNames = [
    "dimanche",
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
  ];

  const dayName = dayNames[date.getUTCDay()]; // Get day of the week in French
  const month = monthNames[date.getUTCMonth()];
  const day = date.getUTCDate();
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${dayName} ${day} ${month} ${year} à ${hours}:${minutes}`;
}

export function dateStringToLongStringUTC(dateString?: string): string {
  if (!dateString || dateString === "") return "";
  const date = new Date(dateString);
  const year = date.getUTCFullYear();

  const month = monthNames[date.getUTCMonth()];
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${day} ${month} ${year} à ${hours}:${minutes}`;
}

export function dateStringToLongStringLocal(dateString?: string): string {
  if (!dateString || dateString === "") return "";
  const dayJsDate = dayjs.utc(dateString).tz();

  const year = dayJsDate.year();

  const month = monthNames[dayJsDate.month()];
  const day = String(dayJsDate.date()).padStart(2, "0");
  const hours = String(dayJsDate.hour()).padStart(2, "0");
  const minutes = String(dayJsDate.minute()).padStart(2, "0");

  return `${day} ${month} ${year} à ${hours}:${minutes}`;
}

export function dateStringToShortStringLocal(dateString?: string): string {
  if (!dateString || dateString === "") return "";
  const dayJsDate = dayjs.utc(dateString).tz();

  const year = dayJsDate.year();
  const month = String(dayJsDate.month() + 1).padStart(2, "0");
  const day = String(dayJsDate.date()).padStart(2, "0");

  return `${year}-${month}-${day} `;
}

export function dateTimeStringToStringLocal(
  dateString?: string | null
): string {
  if (!dateString || dateString === "") return "";
  const dayJsDate = dayjs.utc(dateString).tz();

  const year = dayJsDate.year();

  const month = String(dayJsDate.month() + 1).padStart(2, "0");
  const day = String(dayJsDate.date()).padStart(2, "0");
  const hours = String(dayJsDate.hour()).padStart(2, "0");
  const minutes = String(dayJsDate.minute()).padStart(2, "0");
  if (isNaN(year)) {
    return "";
  }
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
